import React, { useContext } from "react"
import PropTypes from "prop-types"

import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import { ro } from "date-fns/locale"

import { graphql, Link } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Avatar from "@material-ui/core/Avatar"

import Pagination from "material-ui-flat-pagination"

import { localizedSlugPrefix } from "../gatsby/nodeHelpers"

import LangContext from "../contexts/LangContext"
import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"
import GeneralContact from "../components/contacts/GeneralContact"

import Logo from "../img/avatar.png"

const useStyles = makeStyles(theme => ({
  wideContact: {
    position: "sticky",
    top: 140,
    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
    },
  },
  date: {

  },

  title: {
    paddingBottom: theme.spacing(1),
  },
  question: {
    fontStyle: "italic",
  },
  formContainer: {
    padding: theme.spacing(3.2),
  },
  answer: {
    padding: theme.spacing(3.2),
  },
  avatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  sendButton: {
    marginTop: theme.spacing(1),
  },
}))

const QuestionsPage = props => {
  const {
    data: { questionsPage, questionItems },
    pageContext: { locale, slug, total, skip, limit, breadcrumbs },
    location,
  } = props

  const classes = useStyles()
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const items = questionItems.edges.map(r => r.node)

  const schema = questionsPage.data.schema_json.text || ""
  const ogp = questionsPage.data.ogp_tags || ""
  const meta = {
    title: `${questionsPage.data.meta_title}${(skip / limit + 1) > 1 ? ` - Pagina ${skip / limit + 1}` : ``}`,
    description: questionsPage.data.meta_description,
    keywords: questionsPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  const getUrl = item => {
    if (item.data.old_id) {
      return `${localizedSlugPrefix(item)}/intrebare/${item.data.old_id}/${item.data.old_slug ? item.data.old_slug.toLowerCase() : item.uid
        }/`
    }

    return `${localizedSlugPrefix(item)}/intrebare/${item.data.old_slug ? item.data.old_slug.toLowerCase() : item.uid
      }/`
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionTitle component="h1">
                {questionsPage.data.title}{" "}
                <span style={{ fontWeight: 700 }}>MONZA ARES</span>
              </SectionTitle>
            </Grid>
            <Grid item xs={12} md={4} className={classes.contact}>
              <Typography>{i18n.translations.questionIntro}</Typography>
              <br />
              <div className={classes.wideContact}>
                <GeneralContact location={location} locale={locale} formName="Question Form" />
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                {items.map(item => (
                  <Grid item xs={12} key={item.id}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      className={classes.date}
                    >
                      {`${format(
                        parseISO(
                          item.data.question_date || item.first_publication_date
                        ),
                        "PPP",
                        { locale: ro }
                      )} `}
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h2"
                      className={classes.date}
                    >
                      <Link to={getUrl(item)}>{item.data.title.text}</Link>
                    </Typography>
                    <Typography component="p" className={classes.question}>
                      {item.data.content.text}
                    </Typography>

                    <Card className={classes.root} square elevation={0}>
                      <CardActionArea>
                        <CardContent>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar
                              alt="MONZA ARES"
                              src={Logo}
                              className={classes.avatar}
                              style={{ width: 40, height: 40 }}
                            />

                            <Typography variant="h6" component="h3">
                              {i18n.translations.questionAnswer}:
                            </Typography>
                          </div>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                            dangerouslySetInnerHTML={{
                              __html: item.data.reply.text,
                            }}
                          />
                        </CardContent>
                      </CardActionArea>
                    </Card>

                    <br />
                    <Divider />
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Pagination
                    limit={limit}
                    offset={skip}
                    total={total}
                    renderButton={({ offset, children }) => (
                      <Link
                        to={`${localizedSlugPrefix(questionsPage)}/${slug}/${offset < limit ? "" : `page/${offset / limit + 1}/`
                          }`}
                      >
                        {children}
                      </Link>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  )
}

export default QuestionsPage

export const query = graphql`
  query QuestionsPageQuery(
    $slug: String!
    $skip: Int!
    $limit: Int!
    $locale: String!
  ) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    questionsPage: prismicQuestionsPage(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      id
      lang
      data {
        title
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }

    questionItems: allPrismicQuestion(
      skip: $skip
      limit: $limit
      sort: { fields: data___question_date, order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            content {
              text
            }
            reply {
              html
              text
            }
            old_id
            old_slug
            question_date
          }
          first_publication_date
          uid
          lang
        }
      }
    }
  }
`

QuestionsPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    questionsPage: PropTypes.object.isRequired,
    questionItems: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    skip: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
